import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Title, Text, Box, ButtonIcon } from '../../components/Core'
import PageWrapper from '../../components/PageWrapper'
import Screenshot from '../../components/Screenshot'
import NextLink from '../../components/NextLink'
import WebsiteLink from '../../components/WebsiteLink'
import Slides from '../../components/Slides'
import Contact from '../../sections/common/Contact'
import Hero from '../../sections/common/Hero'
import { Keys, Key } from '../../sections/keys/Keys'

import logo from '../../assets/images/cc/logo.jpg'
import doctors from '../../assets/images/cc/doctors.png'
import favicon from '../../assets/images/cc/favicon.png'

import checker from '../../assets/images/cc/symptom-checker.jpg'

import register from '../../assets/images/cc/register.jpg'

import presentation from '../../assets/images/cc/collective-care-intro.jpg'
import portal from '../../assets/images/cc/portal-slide.jpg'
import symptomsEval from '../../assets/images/cc/symptoms-evaluation.jpg'
import interactiveMap from '../../assets/images/cc/interactive-map.jpg'

const WorkSingle = () => {
    return (
        <>
            <PageWrapper>
                <Hero className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="7">
                                <Text variant="tag">FULL STACK</Text>
                                <Title mb="0.5em">
                                    <img
                                        src={logo}
                                        alt="Collective Care"
                                        css={`
                                            max-width: 450px;
                                        `}
                                    />
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    While developing Accountable Ads for clients
                                    like Laser Spine Institute and Atlantic
                                    Spine Center I needed a stack that I could
                                    build tools and services under a single
                                    brand.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    Collective Care was evolved from earlier
                                    project stacks of mine called Cloud9Spine,
                                    Doctopus and YourNeckandBack. We settled on
                                    Collective Care and built the early versions
                                    of symptom checkers and doctor/patient
                                    portals.
                                </Text>
                            </Col>
                            <Col lg="5" style={{ alignSelf: 'center' }}>
                                <img
                                    src={doctors}
                                    alt="Collective Care Doctors"
                                    className="img-fluid w-100"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Hero>

                <div className="mt-lg-3">
                    <Container>
                        <Row>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Client</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Novatex
                                </Title>
                            </Col>
                            <Col lg="4" className="mb-4 mb-lg-0">
                                <Text variant="tag">Time</Text>
                                <Title variant="cardBig" className="mt-3">
                                    Early 2018
                                </Title>
                            </Col>
                            <Col lg="4">
                                <Text variant="tag">Website</Text>

                                <Title variant="cardBig" className="mt-3">
                                    <WebsiteLink
                                        src={favicon}
                                        href="https://www.collective-care.com"
                                    >
                                        collectivecare.com
                                    </WebsiteLink>
                                </Title>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Section className="mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg="5" className="mb-5 pr-lg-5">
                                <img
                                    src={checker}
                                    alt="Symptom Checker"
                                    className="img-fluid w-100"
                                />
                            </Col>
                            <Col lg="7" className="mb-5 pl-lg-5">
                                <Title variant="cardBig" className="mb-4">
                                    Staying Ahead of the Curve
                                </Title>
                                <Text variant="p" mb="1.6em">
                                    The original Collective Care website (not
                                    active) was the first time I learnt React. I
                                    was previously a php/symfony/jquery
                                    developer and spent a majority of my time
                                    building a propriatary website framework we
                                    called "The Core"
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    React was relatively new on the scene, but
                                    was creating waves in the web developer
                                    world for its unique style. I had some
                                    experience with Angular so the original site
                                    was started using Angular instead of React.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    About the time Angular announced v2 I
                                    realized none of the code I was writing
                                    would be compatable with it, I quickly
                                    rewrote the project in React and was so
                                    happy with React it's all I have used for
                                    any website or app since.
                                </Text>
                                <Text variant="p" mb="1.6em">
                                    React opened the door to controlling SVG DOM
                                    elements very easily. I created a symptoms
                                    evaluation with clickable parts of the body
                                    that a vistor can click to begin the
                                    evaluation.
                                </Text>

                                <Text variant="p" mb="1.6em">
                                    Simply by grouping dots together and
                                    connecting to the closet dot in the group
                                    with a line created a neat hover effect.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Keys mt="-5%">
                    <Key name="react" title="01. React" left>
                        My first impression of react was that it was fast and
                        simple to create reuseable components. It reminded me a
                        lot of ColdFusion. It took some time to learn the unique
                        lifecycle of reacts framework but when it finally
                        clicked I was hooked. The Declarative style of
                        programming was very appealing to me.
                    </Key>

                    <Key name="redux" title="02. Redux">
                        I began programming react when components were classes
                        and the immutable nature of state was difficult to
                        understand. Redux was really the only choice of state
                        libraries so I went with it. Looking back, I regret not
                        taking the redux developers advice. He said, "If you
                        don't understand why you are using redux, you shouldn't
                        be using it for your project".
                    </Key>

                    <Key name="symfony" title="03. Silex" left>
                        The front end of Collective Care was to be my first
                        Single Page Application (SPA) but I needed a really
                        simple and fast backend api to serve database
                        information. Silex, now retired, was a lightweight
                        framework by symfony developers for which I was already
                        familiar with. Silex gave me the speed to develop an ORM
                        based backend with a tiny footprint. Silex dependency
                        injection made the api incredible fast and setup was a
                        breeze when coupled with composer, an early package
                        managers.
                    </Key>

                    <Key name="docker" title="04. Docker Swarm">
                        I had been running 5+ servers with capistrano and Chef
                        Manage but I wasn't happy. I constantly found myself
                        fumbling around trying to understand cookbooks, knife,
                        kitchen sinks I thought I was losing my mind. When
                        Docker Swarm became available I jumped on the chance to
                        try something new. I'm so glad I spent the time to learn
                        Docker as it made creating small site modules and
                        deploying so much easier.
                    </Key>
                </Keys>

                <Section mt="22%" bg="dark">
                    <Container>
                        <Row
                            css={`
                                margin-top: -35%;
                            `}
                        >
                            <Col xs="12" className="mb-5">
                                <Slides>
                                    <Screenshot
                                        src={register}
                                        alt="Collective Care Register"
                                    />
                                    <Screenshot
                                        src={presentation}
                                        alt="Collective Care Presentation"
                                    />
                                    <Screenshot
                                        src={portal}
                                        alt="Collective Care Portal"
                                    />
                                    <Screenshot
                                        src={symptomsEval}
                                        alt="Symptoms Checker"
                                    />
                                    <Screenshot
                                        src={interactiveMap}
                                        alt="Interactive Search Map"
                                    />
                                </Slides>
                            </Col>
                        </Row>

                        <div className="text-center mt-lg-5">
                            <Text
                                variant="tag"
                                className="mb-1"
                                color="lightShade"
                            >
                                Next Project
                            </Text>
                            <NextLink to="/works/doctor-consulting/">
                                Doctor Consulting
                            </NextLink>
                        </div>
                    </Container>
                </Section>

                <Box py={4}>
                    <Contact />
                </Box>
            </PageWrapper>
        </>
    )
}
export default WorkSingle
